<script setup lang="ts">
import type { TableHeader, TableOptions } from '@/types/dashboard'
import { ITEMS_PER_PAGE_OPTIONS } from '@/constants/tables'
import { useDebounceFn } from '@vueuse/core';
import { ref } from 'vue';

type Props = {
  items: any[]
  headers: TableHeader[]
  loading?: boolean
  itemsPerPage: number
  page: number
  itemsLength: number
}

defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:options', options: TableOptions): void
}>()

const isFirstUpdate = ref(true)

const debouncedOptionsUpdate = useDebounceFn((options: TableOptions) => {
  if (isFirstUpdate.value) {
    isFirstUpdate.value = false
    return
  }
  emit('update:options', options)
}, 300)
</script>

<template>
  <v-data-table-server
    v-bind="$props"
    :items-per-page-options="ITEMS_PER_PAGE_OPTIONS"
    fixed-header
    @update:options="debouncedOptionsUpdate"
  >
    <template v-for="slot in Object.keys($slots)" #[slot]="slotData">
      <slot :name="slot" v-bind="slotData" />
    </template>
  </v-data-table-server>
</template>