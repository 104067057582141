import { defineStore } from 'pinia'
import { ref, reactive, watch } from 'vue'
import type {
    AgentProductivity,
    CampaignAgentsTableData,
    ProductivityDashboard,
    ProductivityDashboardSummary,
    ProductivityOverview,
    TableOptions,
    TableState,
} from '@/types/dashboard'
import type { Campaign } from '@/types/campaign'
import useHelpers from '@/composables/useHelpers'
import axios, { isAxiosError } from 'axios'
import type { Series } from '@/types/charts'
import { mixed_bar_line_options } from '@/plugins/apexcharts/mixed_bar_line_options'
import { line_chart_options } from '@/plugins/apexcharts/line_chart_options'

const apiBaseUrl = import.meta.env.VITE_API_URL

export const useProductivityDashboardStore = defineStore('productivity_dashboard', () => {
    const { errorHelper } = useHelpers()

    const loading = ref(false)
    const selectedDateGroup = ref('daily')
    const selectedCampaign = ref<Campaign>()

    const productivityOverviewChartOptions = ref()
    const productivityOverviewChartSeries = ref<Series[]>([])

    const activeAgentsRateTrendOptions = ref()
    const activeAgentsRateTrendSeries = ref<Series[]>([])

    const agentsProductivity = reactive<TableState<AgentProductivity>>({
        agents: [],
        total: 0,
        page: 1,
        itemsPerPage: 10,
        loading: false
    })

    const summary = reactive<ProductivityDashboardSummary>({
        active_agents_rate: 0,
        hours_per_active_agent: 0,
        percentage_locked: 0
    })

    function parseProductivityOverviewChartData(data: ProductivityOverview[]) {
        const actualHrs: number[][] = new Array(data.length);
        const releasedHrs: number[][] = new Array(data.length);
        const scheduledHrs: number[][] = new Array(data.length);
        const lockedHrs: number[][] = new Array(data.length);

        for (let i = 0; i < data.length; i++) {
            const timestamp = new Date(data[i].report_date).getTime();

            actualHrs[i] = [timestamp, data[i].actual_hrs ? Math.round(data[i].actual_hrs * 100) / 100 : 0];
            releasedHrs[i] = [timestamp, data[i].released_hrs ? Math.round(data[i].released_hrs * 100) / 100 : 0];
            scheduledHrs[i] = [timestamp, data[i].scheduled_hrs ? Math.round(data[i].scheduled_hrs * 100) / 100 : 0];
            lockedHrs[i] = [timestamp, data[i].locked_hrs ? Math.round(data[i].locked_hrs * 100) / 100 : 0];
        }

        productivityOverviewChartSeries.value = [
            {
                name: 'actual_hrs',
                data: actualHrs,
                type: 'column',
                color: '#5723B4'
            },
            {
                name: 'released_hrs',
                data: releasedHrs,
                type: 'line',
                color: '#8C8CE8'
            },
            {
                name: 'scheduled_hrs',
                data: scheduledHrs,
                type: 'line',
                color: '#EB74C4'
            },
            {
                name: 'locked_hrs',
                data: lockedHrs,
                type: 'line',
                color: '#2B2E41'
            }
        ];

        productivityOverviewChartOptions.value = {
            ...mixed_bar_line_options,
            tooltip: {
                x: {
                    show: true,
                    formatter: function (value: string) {
                        return new Date(value).toLocaleString('en-US', {
                            day: '2-digit',
                            month: 'short',
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                        });
                    },
                }
            },
            yaxis: {
                labels: {
                    show: true,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Nunito',
                    },
                    formatter: (value: number) => {
                        return value.toFixed(0)
                    }
                },
            },
            xaxis: {
                type: 'datetime',
                min: new Date(new Date().setHours(0, 0, 0, 0) - 24 * 60 * 60 * 1000).getTime(),
                max: new Date(new Date().setHours(23, 59, 59, 999) - 24 * 60 * 60 * 1000).getTime(),
                labels: {
                    show: true,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Nunito',
                    },
                    formatter: function (value: string, timestamp: number) {
                        return new Date(timestamp).toLocaleString('en-US', {
                            day: '2-digit',
                            month: 'short',
                        });
                    },
                    offsetX: 10,
                },
                tooltip: {
                    enabled: false
                },
            },
        }
    }

    function parseActiveAgentsRateTrendData(data: { date: string, active_agents_rate: number }[]) {
        activeAgentsRateTrendSeries.value = [
            {
                name: 'Agent Rate',
                data: data.map(item => Number((item.active_agents_rate * 100).toFixed(0))),
                type: 'line',
                color: '#8C8CE8'
            }
        ]

        activeAgentsRateTrendOptions.value = {
            ...line_chart_options,
            xaxis: {
                type: 'datetime',
                labels: {
                    show: true,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Nunito',
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Nunito',
                    }
                }
            },
            labels: data.map(item => item.date) || [],
            dataLabels: {
                enabled: true,
                enabledOnSeries: [0],
                formatter: function (val: number) {
                    return val + '%'
                }
            },
        }
    }

    async function getProductivityDashboardData(campaign_id: number) {
        try {
            const { data } = await axios.get<ProductivityDashboard>(
                `${apiBaseUrl}/api/dashboards/by_campaign/${campaign_id}/productivity?limit=10&with_count=true`
            )
            return data
        } catch (error) {
            if (isAxiosError(error)) {
                errorHelper(error?.response?.data)
            }
        }
    }

    async function getAgentsProductivityTableData({
        id,
        offset,
        limit,
        sort_by,
        sort_order
    }: {
        id: number
        offset: number
        limit: number
        type: string
        sort_by?: string
        sort_order?: boolean | 'asc' | 'desc'
    }) {
        try {
            const { data } = await axios.get<
                CampaignAgentsTableData<AgentProductivity>
            >(
                `${apiBaseUrl}/api/dashboards/by_campaign/${id}/productivity/intra_agents`,
                {
                    params: {
                        limit,
                        offset,
                        with_count: true,
                        sort_by,
                        sort_order
                    }
                }
            )
            return data
        } catch (error) {
            if (isAxiosError(error)) {
                errorHelper(error?.response?.data)
            }
        }
    }

    async function fetchProductivityData() {
        if (!selectedCampaign.value) return

        try {
            loading.value = true
            const data = await getProductivityDashboardData(selectedCampaign.value.id)

            if (!data) return

            summary.active_agents_rate = data.summary.active_agents_rate
            summary.hours_per_active_agent = data.summary.hours_per_active_agent
            summary.percentage_locked = data.summary.percentage_locked

            agentsProductivity.agents = data.agents_productivity.results
            agentsProductivity.total = data.agents_productivity.count

            parseProductivityOverviewChartData(data.productivity_overview || [])
            parseActiveAgentsRateTrendData(data.active_agents_rate_trend || [])
        } catch (error) {
            console.error('Error fetching dashboard data:', error)
        } finally {
            loading.value = false
        }
    }

    async function handleTableUpdate(options: TableOptions) {
        if (!selectedCampaign.value) return

        const state = agentsProductivity
        state.loading = true

        try {
            const params: any = {
                id: selectedCampaign.value.id,
                offset: (options.page - 1) * options.itemsPerPage,
                limit: options.itemsPerPage
            }

            if (options.sortBy?.[0]) {
                params.sort_by = options.sortBy[0].key
                params.sort_order = options.sortBy[0].order
            }

            const data = await getAgentsProductivityTableData(params)

            if (data) {
                state.agents = data.results
                state.total = data.count
                state.page = options.page
                state.itemsPerPage = options.itemsPerPage
            }
        } catch (error) {
            console.error(`Error updating table:`, error)
        } finally {
            state.loading = false
        }
    }

    function reset() {
        loading.value = false
        selectedDateGroup.value = 'daily'
        summary.active_agents_rate = 0
        summary.hours_per_active_agent = 0
        summary.percentage_locked = 0
    }

    watch(selectedCampaign, () => {
        reset()
        fetchProductivityData()
    })

    return {
        loading,
        selectedDateGroup,
        selectedCampaign,
        summary,
        agentsProductivity,
        productivityOverviewChartSeries,
        productivityOverviewChartOptions,
        activeAgentsRateTrendSeries,
        activeAgentsRateTrendOptions,
        reset,
        fetchProductivityData,
        handleTableUpdate
    }
})
