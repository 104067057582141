<script setup lang="ts">
import "leaflet/dist/leaflet.css"
import { nextTick, watch } from 'vue'
import { useGeographyMap } from '@/composables/useGeographyMap'
import type { Geography } from '@/types/dashboard';

interface Props {
    geographies: Geography[]
    loading?: boolean
}

const props = defineProps<Props>()

const {
    initMap,
    updateMarkers,
} = useGeographyMap()

watch(
    () => props.geographies,
    async (newGeographies) => {
        // Wait for DOM update
        await nextTick()
        initMap()
        if (newGeographies.length) {
            updateMarkers(newGeographies)
        }
    },
    { deep: true }
)
</script>

<template>
    <section class="tw-flex tw-flex-col tw-gap-y-4">
        <h3 class="tw-mr-auto tw-w-full tw-text-left tw-text-lg tw-font-black tw-text-shyftoff-purple">
            Geographies
        </h3>

        <v-skeleton-loader v-if="loading" class="tw-h-[600px] tw-w-full tw-rounded-lg" />

        <div v-show="!loading && geographies.length" id="map" class="tw-h-[600px] tw-w-full tw-rounded-lg" />

        <template v-if="!loading && !geographies.length">
            <h5 class="tw-my-6 tw-mt-8 tw-text-center tw-text-lg tw-font-extrabold tw-text-darker-light-text">
                No geography data available.
            </h5>
            <img src="@/assets/images/empty-state--tables.svg"
                class="tw-relative tw-left-1/2 tw-block !tw-min-h-[160px] tw-max-w-full -tw-translate-x-1/2 tw-scale-90 tw-object-cover sm:!tw-min-h-[270px]"
                alt="">
        </template>
    </section>
</template>

<style lang="scss" scoped>
:deep(.leaflet-control-attribution) {
    display: none !important;
}

:deep(.custom-marker) {
    background: transparent;
    border: none;

    .marker-outer-circle {
        position: relative;
        width: 50px;
        height: 50px;
        background: rgba(87, 35, 180, 0.2);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.not-valid {
            background: rgb(185 28 28 / 0.3);
        }
    }

    .marker-inner-circle {
        width: 40px;
        height: 40px;
        background: rgb(87, 35, 180);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 10px rgba(87, 35, 180, 0.5);

        &.not-valid {
            background: rgb(185 28 28 / 0.7);
        }
    }

    .marker-value {
        color: white;
        font-weight: bold;
        font-size: 14px;
    }
}

:deep(.legend) {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Nunito', sans-serif;

    h4 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    .legend-item {
        display: flex;
        align-items: center;
        gap: 8px;

        i {
            width: 14px;
            height: 14px;
            border-radius: 50%;
        }
    }
}
</style>