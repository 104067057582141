<script setup lang="ts">
import BaseDataTable from '@/components/common/DataTable.vue'
import { CIP_TABLE_HEADERS } from '@/constants/tables'
import { useAgentsDashboardStore } from '@/stores/agents-dashboard';
import { CampaignStatusEnum } from '@/types/campaign';
import type { TableOptions } from '@/types/dashboard';
import { formatAgentStatusGroup } from '@/utils/dashboard_table';
import { storeToRefs } from 'pinia';

const agentsDashboardStore = useAgentsDashboardStore()
const {
    loading,
    certification,
} = storeToRefs(agentsDashboardStore)

</script>

<template>
    <section class="tw-flex tw-flex-col tw-gap-y-4">
        <h3 class="tw-text-lg tw-font-black tw-text-shyftoff-purple">
            Certification In Progress
        </h3>

        <div class="tw-max-w-full">
            <div v-if="loading">
                <VSkeletonLoader type="table-thead, table-row-divider@7" />
            </div>

            <BaseDataTable v-else-if="certification.agents.length && !loading" :items="certification.agents" item-value="idx"
                :headers="CIP_TABLE_HEADERS" :loading="certification.loading" :page="certification.page"
                :items-per-page="certification.itemsPerPage" :items-length="certification.total"
                @update:options="(options: TableOptions) => agentsDashboardStore.handleTableUpdate(options, 'certification')" class="tw-max-h-[450px] md:tw-max-h-[880px]">
                <template v-slot:loader>
                    <v-progress-linear color="primary" :height="3" indeterminate />
                </template>

                <template v-slot:headers="{ headers, sortBy, toggleSort, isSorted }">
                    <tr>
                        <template v-for="(headerRow, rowIndex) in headers" :key="rowIndex">
                            <th v-for="header in headerRow" :key="header.title"
                                class="tw-group !tw-border-none tw-text-[13px] !tw-font-bold tw-uppercase tw-text-light-text"
                                :class="{ 'tw-cursor-pointer': header.sortable }"
                                @click="header.sortable ? toggleSort(header) : null">
                                <div class="tw-flex tw-w-full tw-items-center tw-gap-x-2"
                                    :class="{ 'tw-justify-center': header.key === 'shyftoff_id', 'tw-justify-between': header.sortable }">
                                    {{ header.title }}

                                    <span v-if="header.sortable" class="tw-flex tw-items-center">
                                        <i v-if="!isSorted(header)"
                                            class="mdi mdi-arrow-up tw-text-base tw-text-transparent group-hover:tw-text-gray-400" />

                                        <i v-else-if="sortBy.find((sort: any) => sort.key === header.key)?.order === 'asc'"
                                            class="mdi mdi-arrow-up tw-text-base" />
                                        <i v-else class="mdi mdi-arrow-down tw-text-base" />
                                    </span>
                                </div>
                            </th>
                        </template>
                    </tr>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                    <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-pr-4">
                        <div class="tw-inline-block tw-align-middle">
                            <div v-if="item?.avatar" class="tw-h-8 tw-w-8">
                                <v-img :src="item.avatar" aspect-ratio="1/1" :cover="true"
                                    class="tw-rounded-full"></v-img>
                            </div>
                            <div
                                class="!tw-mb-0 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-solid tw-border-grey-border/50 tw-text-center tw-opacity-80">
                                <svg class="tw-inline-block" width="22" height="21" viewBox="0 0 22 21" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21.4527 19.5313C19.906 16.8572 17.5223 14.9397 14.7405 14.0307C16.1165 13.2116 17.1856 11.9634 17.7835 10.4778C18.3815 8.9923 18.4753 7.35155 18.0505 5.80755C17.6257 4.26355 16.7058 2.90168 15.4321 1.93108C14.1584 0.960478 12.6013 0.434814 10.9999 0.434814C9.39857 0.434814 7.84148 0.960478 6.56778 1.93108C5.29408 2.90168 4.3742 4.26355 3.94941 5.80755C3.52462 7.35155 3.6184 8.9923 4.21636 10.4778C4.81432 11.9634 5.88339 13.2116 7.25939 14.0307C4.4776 14.9387 2.09392 16.8562 0.547127 19.5313C0.490403 19.6238 0.452779 19.7267 0.436473 19.834C0.420168 19.9413 0.425512 20.0507 0.452191 20.1559C0.478869 20.2611 0.526341 20.3598 0.591806 20.4463C0.657271 20.5329 0.739403 20.6054 0.833355 20.6597C0.927308 20.7139 1.03118 20.7488 1.13884 20.7623C1.2465 20.7758 1.35577 20.7676 1.4602 20.7381C1.56462 20.7087 1.6621 20.6586 1.74687 20.5909C1.83164 20.5232 1.90199 20.4392 1.95377 20.3438C3.8672 17.037 7.24924 15.0626 10.9999 15.0626C14.7506 15.0626 18.1327 17.037 20.0461 20.3438C20.0979 20.4392 20.1682 20.5232 20.253 20.5909C20.3378 20.6586 20.4353 20.7087 20.5397 20.7381C20.6441 20.7676 20.7534 20.7758 20.861 20.7623C20.9687 20.7488 21.0726 20.7139 21.1665 20.6597C21.2605 20.6054 21.3426 20.5329 21.4081 20.4463C21.4735 20.3598 21.521 20.2611 21.5477 20.1559C21.5744 20.0507 21.5797 19.9413 21.5634 19.834C21.5471 19.7267 21.5095 19.6238 21.4527 19.5313ZM5.31244 7.75008C5.31244 6.6252 5.646 5.52558 6.27096 4.59027C6.89591 3.65497 7.78417 2.92599 8.82343 2.49551C9.86268 2.06504 11.0062 1.95241 12.1095 2.17186C13.2128 2.39131 14.2262 2.933 15.0216 3.72841C15.817 4.52382 16.3587 5.53723 16.5782 6.6405C16.7976 7.74377 16.685 8.88734 16.2545 9.92659C15.824 10.9658 15.095 11.8541 14.1597 12.4791C13.2244 13.104 12.1248 13.4376 10.9999 13.4376C9.49201 13.436 8.04631 12.8362 6.98005 11.77C5.91379 10.7037 5.31405 9.258 5.31244 7.75008Z"
                                        fill="#2b2e41" />
                                </svg>
                            </div>
                        </div>

                        <p
                            class="tw-ml-4 tw-flex tw-flex-col tw-items-start tw-justify-center tw-text-base tw-text-regular-text">
                            {{ item.name }}
                        </p>
                    </div>
                </template>

                <template v-slot:[`item.shyftoff_id`]="{ item }">
                    <span
                        class="tw-rounded-full tw-border tw-border-solid tw-border-light-purple tw-px-4 tw-py-1 tw-text-sm tw-font-medium tw-text-regular-text">
                        {{ item.shyftoff_id }}
                    </span>
                </template>

                <template v-slot:[`item.qualification`]="{ item }">
                    <span class="tw-text-base tw-text-regular-text">
                        {{ item.qualification_name }}
                    </span>
                </template>

                <template v-slot:[`item.progress`]="{ item }">
                    <div class="tw-flex tw-flex-col tw-gap-y-1">
                        <v-progress-linear buffer-value="100" color="primary"
                            :model-value="item.certification_progress * 100" :height="11" rounded />
                        <span class="tw-text-sm tw-font-medium tw-text-light-text">
                            {{ (item.certification_progress * 100).toFixed(0) }}% completed
                        </span>
                    </div>
                </template>

                <template v-slot:[`item.quizzes`]="{ item }">
                    <div class="tw-flex tw-items-center tw-gap-x-2">
                        <i class="mdi mdi-book-open-page-variant-outline tw-text-xl tw-text-light-text" />
                        <span class="tw-text-base tw-text-regular-text">
                            {{ item.number_of_quizes !== null ? `${item.completed_units} / ${item.total_units}` : '-' }}
                        </span>
                    </div>
                </template>

                <template v-slot:[`item.score`]="{ item }">
                    <div class="tw-flex tw-items-center tw-gap-x-2">
                        <i class="mdi mdi-bullseye-arrow tw-text-xl" :class="{
                            'tw-text-light-text': item.average_score === null,
                            'tw-text-coral_red': item.average_score !== null && item.average_score < 50,
                            'tw-text-yellow_crayola': item.average_score >= 50 && item.average_score < 70,
                            'tw-text-green_crayola': item.average_score >= 70,
                        }" />
                        <span class="tw-text-center tw-text-base">
                            {{ item.average_score !== null ? item.average_score : '-' }}
                        </span>
                    </div>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <div class="tw-flex tw-flex-col tw-gap-y-1">
                        <span class="tw-text-base" :class="{
                            'tw-text-green_crayola': item.campaign_status.agent_status_group === CampaignStatusEnum.PRODUCTION,
                            'tw-text-coral_red': item.campaign_status.agent_status_group === CampaignStatusEnum.REMOVED,
                            'tw-text-regular-text': ''
                        }">
                            {{ formatAgentStatusGroup(item.campaign_status.agent_status_group) }}
                        </span>
                        <span class="tw-text-sm tw-text-light-text">{{ item.campaign_status.name }}</span>
                    </div>
                </template>
            </BaseDataTable>

            <template v-else>
                <h5 class="tw-my-6 tw-mt-8 tw-text-center tw-text-lg tw-font-extrabold tw-text-darker-light-text">
                    No data available.
                </h5>
                <img src="@/assets/images/empty-state--tables.svg"
                    class="tw-relative tw-left-1/2 tw-block !tw-min-h-[160px] tw-max-w-full -tw-translate-x-1/2 tw-scale-90 tw-object-cover sm:!tw-min-h-[270px]"
                    alt="">
            </template>
        </div>
    </section>
</template>