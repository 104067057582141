export enum CampaignStatusEnum {
  APPLIED = 1,
  IN_PROGRESS = 2,
  PRODUCTION = 6,
  OPTOUT = 100,
  REMOVED = 101
}

export type Campaign = {
  agent_status: {
    agent_status_group: number
    code: string
    description: string | null
    id: number
    is_default: boolean
    name: string
    order: number
    status_group: number
  }
  description: string | null
  id: number
  is_active: boolean | null
  is_friday_shift: boolean
  is_monday_shift: boolean
  is_saturday_shift: boolean
  is_sunday_shift: boolean
  is_thursday_shift: boolean
  is_tuesday_shift: boolean
  is_wednesday_shift: boolean
  lms_url: string
  support_material_url: string
  logo_url: string
  max_hourly_pay: string | number | null
  min_hourly_pay: string | number | null
  name: string | null
  shift_hours_from: string | null
  shift_hours_to: string | null
  shift_timezone: string | null
  status: string | number | null
  training_bonus: string | number | null
  wysiwyg_description: string
  campaign_status_updated_at?: string
  agent_status_updated_at?: string
  updated_at?: string
}
