<script setup lang="ts">
import ActiveAgentsRateChart from '@/components/productivity-dashboard/ActiveAgentsRateChart.vue';
import IntraAgentProductivityTable from '@/components/productivity-dashboard/IntraAgentProductivityTable.vue';
import ProductivityDashboardHeader from '@/components/productivity-dashboard/ProductivityDashboardHeader.vue';
import ProductivityOverviewChart from '@/components/productivity-dashboard/ProductivityOverviewChart.vue';
import useCampaigns from '@/composables/useCampaigns';
import { useCampaignStore } from '@/stores/campaigns';
import { useProductivityDashboardStore } from '@/stores/productivity-dashboard';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';


const productivityDashboardStore = useProductivityDashboardStore()
const {
    selectedCampaign,
    loading,
    summary,
} = storeToRefs(productivityDashboardStore)

const { getUserCampaigns } = useCampaigns()
const { activeCampaigns, } = storeToRefs(useCampaignStore())

onMounted(async () => {
    loading.value = true
    await getUserCampaigns({ is_active: true })
    selectedCampaign.value = activeCampaigns.value[0]
    loading.value = false
})
</script>

<template>
    <article class="tw-relative tw-flex tw-flex-col tw-gap-y-8">
        <ProductivityDashboardHeader v-model:selected-campaign="selectedCampaign" :active-campaigns="activeCampaigns"
            :loading="loading" :summary="summary" />

        <ProductivityOverviewChart />

        <ActiveAgentsRateChart />

        <IntraAgentProductivityTable />
    </article>
</template>

<style scoped></style>