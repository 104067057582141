<script setup lang="ts">
import type { Campaign } from '@/types/campaign'
import type { CampaignAgentsSummary } from '@/types/dashboard'
import DashboardHeader from '@/components/common/DashboardHeader.vue'
import SparkLinesChart from '../dashboard/SparkLinesChart.vue';
import { agentDashboardSparkLineVariants } from '@/plugins/apexcharts/sparkline_options';
import { computed } from 'vue';

interface Props {
  selectedCampaign?: Campaign
  activeCampaigns: Campaign[]
  loading: boolean
  summary: CampaignAgentsSummary
}

const props = defineProps<Props>()

const historyKeys = [
  'production_agents',
  'certification_in_progress_agents',
  'total'
] as const

const chartOptions = computed(() => {
  if (!props.summary?.campaign_agent_count_history) return []

  return agentDashboardSparkLineVariants.map((options, index) => ({
    ...options,
    title: {
      ...options.title,
      text: String(props.summary[options.title.text as keyof CampaignAgentsSummary] || 0)
    },
    labels: props.summary.campaign_agent_count_history[historyKeys[index]]?.map(item => item.report_date) || []
  }))
})

const chartSeries = computed(() => {
  if (!props.summary?.campaign_agent_count_history) return []

  return [
    {
      name: 'Production Agents',
      data: props.summary.campaign_agent_count_history.production_agents?.map(item => item.count) || []
    },
    {
      name: 'Certification In Progress',
      data: props.summary.campaign_agent_count_history.certification_in_progress_agents?.map(item => item.count) || []
    },
    {
      name: 'Total',
      data: props.summary.campaign_agent_count_history.total?.map(item => item.count) || []
    }
  ]
})
</script>

<template>
  <DashboardHeader title="Agents Dashboard" :selected-campaign="selectedCampaign" :active-campaigns="activeCampaigns"
    :loading="loading" @update:selected-campaign="$emit('update:selectedCampaign', $event)">
    <template #stats>
      <div class="tw-flex tw-w-full tw-flex-col tw-gap-4 md:tw-flex-row">
        <template v-if="loading">
          <v-skeleton-loader v-for="(_, index) in [1, 2, 3]" :key="index"
            class="tw-h-[164px] tw-w-full tw-rounded-lg md:tw-flex-1" />
        </template>

        <template v-else-if="chartOptions.length && chartSeries.length">
          <SparkLinesChart v-for="(options, index) in chartOptions" :key="index" :series="[chartSeries[index]]"
            :options="options" />
        </template>
      </div>
    </template>
  </DashboardHeader>
</template>