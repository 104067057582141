export const sparkline_options = {
  chart: {
    sparkline: {
      enabled: true
    }
  },
  stroke: {
    curve: 'straight'
  },
  fill: {
    opacity: 1
  },
  yaxis: {
    min: 0,
    labels: {
      show: false
    }
  },
  xaxis: {
    type: 'datetime',
    labels: {
      show: false
    }
  },
  title: {
    style: {
      fontSize: '20px',
      fontFamily: 'Nunito',
      cssClass: 'apexcharts-yaxis-title'
    }
  },
  subtitle: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: 'Nunito',
      cssClass: 'apexcharts-yaxis-title'
    }
  }
}

export const agentDashboardSparkLineVariants = [
  {
    color: '#8C8CE8',
    title: 'Production Agents',
    summaryKey: 'total_production_agents'
  },
  {
    color: '#C48AFA',
    title: 'Certification In Progress',
    summaryKey: 'total_certification_in_progress_agents'
  },
  {
    color: '#EB74C4',
    title: 'Total Agents',
    summaryKey: 'total_agents'
  }
].map(variant => ({
  ...sparkline_options,
  colors: [variant.color],
  title: {
    ...sparkline_options.title,
    text: variant.summaryKey
  },
  subtitle: {
    ...sparkline_options.subtitle,
    text: variant.title
  }
}))
