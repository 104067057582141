import { CampaignStatusEnum } from "@/types/campaign"

export function formatAgentStatusGroup(group_id: number): string {
    let groupLabel = ''

    switch (group_id) {
        case CampaignStatusEnum.IN_PROGRESS:
            groupLabel = 'In Progress'
            break
        case CampaignStatusEnum.PRODUCTION:
            groupLabel = 'Approved'
            break
        case CampaignStatusEnum.OPTOUT:
            groupLabel = 'OptOut'
            break
        case CampaignStatusEnum.REMOVED:
            groupLabel = 'Removed'
            break
        default:
            groupLabel = 'Applied'
            break
    }

    return groupLabel
}