<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useProductivityDashboardStore } from '@/stores/productivity-dashboard';

const productivityDashboardStore = useProductivityDashboardStore()
const { loading, activeAgentsRateTrendOptions, activeAgentsRateTrendSeries } = storeToRefs(productivityDashboardStore)

</script>

<template>
    <section class="tw-flex tw-flex-col tw-gap-y-6">
        <h3 class="tw-mr-auto tw-w-full tw-text-left tw-text-lg tw-font-black tw-text-shyftoff-purple">
            Active Agent Rate Trend
        </h3>

        <template v-if="loading">
            <v-skeleton-loader class="tw-h-[500px] tw-w-full tw-rounded-lg" />
        </template>

        <apexchart v-else-if="activeAgentsRateTrendSeries.length && !loading" width="100%" height="500"
            :options="activeAgentsRateTrendOptions" :series="activeAgentsRateTrendSeries" />

        <template v-else>
            <h5 class="tw-my-6 tw-mt-8 tw-text-center tw-text-lg tw-font-extrabold tw-text-darker-light-text">
                No data available.
            </h5>
            <img src="@/assets/images/empty-state--tables.svg"
                class="tw-relative tw-left-1/2 tw-block !tw-min-h-[160px] tw-max-w-full -tw-translate-x-1/2 tw-scale-90 tw-object-cover sm:!tw-min-h-[270px]"
                alt="">
        </template>
    </section>
</template>