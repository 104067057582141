<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useProductivityDashboardStore } from '@/stores/productivity-dashboard';
import { ref } from 'vue';

const dateGroupButtons = [
    {
        value: 'monthly'
    },
    {
        value: 'weekly'
    },
    {
        value: 'daily',
    }
]

const productivityDashboardStore = useProductivityDashboardStore()
const { loading, selectedDateGroup, productivityOverviewChartOptions, productivityOverviewChartSeries } = storeToRefs(productivityDashboardStore)

const chart = ref()


const handleZoom = (dateGroup: string) => {
    selectedDateGroup.value = dateGroup

    if (chart.value) {
        const endDate = new Date()
        const startDate = new Date()

        endDate.setHours(0, 0, 0, 0)
        endDate.setTime(endDate.getTime() - 24 * 60 * 60 * 1000)
        startDate.setHours(0, 0, 0, 0)
        startDate.setTime(startDate.getTime() - 24 * 60 * 60 * 1000)

        if (dateGroup === 'monthly') {
            startDate.setMonth(endDate.getMonth() - 1)
            endDate.setHours(23, 59, 59, 999)
        } else if (dateGroup === 'weekly') {
            startDate.setDate(endDate.getDate() - 6)
            endDate.setHours(23, 59, 59, 999)
        } else if (dateGroup === 'daily') {
            endDate.setHours(23, 59, 59, 999)
        }

        chart.value.zoomX(
            startDate.getTime(),
            endDate.getTime()
        )
    }
}
</script>

<template>
    <section class="tw-flex tw-flex-col tw-gap-y-6">
        <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-y-4">
            <h3 class="tw-mr-auto tw-flex tw-text-left tw-text-lg tw-font-black tw-text-shyftoff-purple">
                Productivity Overview</h3>

            <div class="tw-flex tw-gap-x-4">
                <button v-for="(button, index) in dateGroupButtons" :key="index"
                    class="tw-w-[151px] tw-rounded-lg tw-px-4 tw-py-2 tw-font-sans tw-capitalize tw-text-white" :class="{
                        'tw-bg-primary': selectedDateGroup === button.value,
                        'tw-bg-gray-400': selectedDateGroup !== button.value
                    }" @click="handleZoom(button.value)">
                    {{ button.value }}
                </button>
            </div>
        </div>

        <template v-if="loading">
            <v-skeleton-loader class="tw-h-[500px] tw-w-full tw-rounded-lg" />
        </template>

        <apexchart v-else-if="productivityOverviewChartSeries.length && !loading" ref="chart" width="100%" height="500"
            :options="productivityOverviewChartOptions" :series="productivityOverviewChartSeries" />

        <template v-else>
            <h5 class="tw-my-6 tw-mt-8 tw-text-center tw-text-lg tw-font-extrabold tw-text-darker-light-text">
                No data available.
            </h5>
            <img src="@/assets/images/empty-state--tables.svg"
                class="tw-relative tw-left-1/2 tw-block !tw-min-h-[160px] tw-max-w-full -tw-translate-x-1/2 tw-scale-90 tw-object-cover sm:!tw-min-h-[270px]"
                alt="">
        </template>
    </section>
</template>