<template>
    <div>
        <h1
            class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-black tw-w-full tw-text-center md:tw-text-left">
            Forecast Dashboard
        </h1>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped></style>