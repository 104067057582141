import { ref, onUnmounted } from 'vue'
import * as L from 'leaflet'
import type { Geography, GeographyMarker } from '@/types/dashboard'

export function useGeographyMap() {
  const map = ref<L.Map>()
  const markers = ref<L.Marker[]>([])
  const geographyMarkers = ref<GeographyMarker[]>([])

  const parseCoordinates = (coordinatesString: string): [number, number] => {
    try {
      const [lat, lng] = coordinatesString.split(',').map(coord => parseFloat(coord.trim()))
      return [lat, lng]
    } catch (error) {
      return [0, 0]
    }
  }

  const createCustomIcon = (marker: GeographyMarker): L.DivIcon => {
    return L.divIcon({
      className: 'custom-marker',
      html: `
        <div class="marker-outer-circle ${!marker.is_valid ? 'not-valid' : ''}">
          <div class="marker-inner-circle ${!marker.is_valid ? 'not-valid' : ''}">
            <span class="marker-value">${marker.value}</span>
          </div>
        </div>
      `,
      iconSize: [40, 40],
      iconAnchor: [20, 20]
    })
  }

  const createLegend = () => {
    const legendItems = [
      { color: '#5822b4', label: 'Approved States' },
      { color: '#FF3C3C', label: 'Not Approved States' },
    ];

    const legend = new L.Control({ position: "bottomleft" });

    legend.onAdd = function () {
      const div = L.DomUtil.create("div", "legend");
      div.innerHTML += "<h4>Agent Count by State</h4>";
      legendItems.forEach(item =>
        div.innerHTML += `<div class="legend-item"><i style="background: ${item.color}"></i><span>${item.label}</span></div>`
      );

      return div;
    };

    map.value && legend.addTo(map.value)
  }

  const initMap = () => {
    if (map.value) {
      map.value.remove()
    }

    const southWest = L.latLng(15.0, -190.0)
    const northEast = L.latLng(72.0, -30.0)
    const bounds = L.latLngBounds(southWest, northEast)

    map.value = L.map('map', {
      maxBounds: bounds,
      maxBoundsViscosity: 1.0,
      minZoom: 3,
      maxZoom: 19
    }).setView([39.8283, -98.5795], 5)

    L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
      maxZoom: 19,
    }).addTo(map.value)

    createLegend()
  }

  const updateMarkers = (geographies: Geography[]) => {
    markers.value.forEach(marker => marker.remove())
    markers.value = []

    geographyMarkers.value = geographies.map(geo => {
      const [lat, lng] = parseCoordinates(geo.coordinates)
      return {
        lat,
        lng,
        value: geo.count,
        is_valid: geo.is_approved
      }
    })

    geographyMarkers.value.forEach((marker: GeographyMarker) => {
      if (marker.lat && marker.lng) {
        const newMarker = L.marker(
          [marker.lat, marker.lng],
          { icon: createCustomIcon(marker) }
        ).addTo(map.value!)

        markers.value.push(newMarker)
      }
    })
  }

  const cleanup = () => {
    if (map.value) {
      map.value.remove()
      map.value = undefined
    }
    markers.value = []
    geographyMarkers.value = []
  }

  onUnmounted(() => {
    cleanup()
  })

  return {
    map,
    markers,
    geographyMarkers,
    initMap,
    updateMarkers,
    cleanup
  }
}