import { defineStore, type StateTree } from 'pinia'
import type { Agent } from '@/types/agent'
import useUser from '@/composables/useUser'
import type { Qualification } from '@/types/qualification'

export const useProfileStore = defineStore('profile', {
  state: () => ({
    profile: {
      username: '' as string,
      email: '' as string,
      shyftoff_id: '' as string,
      groups: [] as any[],
      agent: null as unknown as Agent
    },
    error: null as string | null,
    agents: [],
    isLoading: true
  }),
  getters: {
    getProfile: (state: StateTree) => state.profile,
    getAgents: (state: StateTree) => state.agents,
    isCampaingReviewer: (state: StateTree) => state.profile.agent?.reviewed_campaigns.length > 0,
    profileQualifications: (state: StateTree) => state.profile.agent?.qualifications as Qualification[],
    isAgentRole: (state: StateTree) =>
      state.profile?.agent && !state.profile?.groups.includes('Admins'),
    isAdmin: (state: StateTree) => state.profile?.groups.includes('Admins'),
    isCustomer: (state: StateTree) => state.profile?.groups.includes('Customers'),
  },
  actions: {
    setProfile(n: any) {
      this.profile = n
    },
    setAvatar(n: any) {
      this.profile.agent.avatar_url = n
    },
    setCurrentAgent(n: any) {
      this.profile.agent = n
    },
    changeLoadingStatus() {
      this.isLoading = false
    },
    setError(e: string | null) {
      this.error = e
    },
    async setInitialProfileData() {
      const { userDetails, getUserDetails } = useUser()
      if (!this.getProfile?.username) {
        await getUserDetails()
        this.setProfile(userDetails.value)
        this.changeLoadingStatus()
      }
      return userDetails
    }
  },
  persist: true
})
