export const line_chart_options = {
  chart: {
    type: 'line',
    zoom: {
      enabled: false
    },
    toolbar: {
      tools: {
        download: true,
        selection: false,
        zoom: false,
        pan: false,
        reset: false
      }
    }
  },
  dataLabels: {
    enabled: true
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  stroke: {
    width: 4,
    dashArray: 4
  },
  legend: {
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Nunito',
    markers: {
      size: 8,
      shape: 'square'
    }
  }
}
